import { render, staticRenderFns } from "./PrivacyPolicy.vue?vue&type=template&id=20453a90&scoped=true&"
import script from "./PrivacyPolicy.vue?vue&type=script&lang=js&"
export * from "./PrivacyPolicy.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyPolicy.vue?vue&type=style&index=0&id=20453a90&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20453a90",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!/var/www/html/insights/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
installComponents(component, {VContainer,VFlex,VLayout})
