<template>
<!--    <v-app>-->
        <v-container>
            <v-layout row>
                <v-flex xs12 sm12 md12 lg12 px-2>
                    <h1 style="text-align: center;"><strong>Privacy Policy</strong></h1>
                    <br><br>
                    <p class="para">Bindez Insights is committed to safeguarding the Personal Data of Users. This privacy policy (&ldquo;Privacy Policy&rdquo;) is part of the ToS <router-link style="text-decoration: none" to="/terms_and_conditions" target="_blank">Terms of Service</router-link>. Each word defined in the ToS has the same meaning in the Privacy Policy.</p>
                    <br>
                    <p class="para">By using the Services, Users agree to abide and be bound by this Privacy Policy, which may be modified or updated from time to time without notice. In this event the update will be displayed on Bindez Insights&rsquo; websites.</p>
                    <br>
                    <h2 class="privacy__title">I. Nature of Personal Data Collected by Bindez Insights</h2>
                    <br>
                    <p class="para">Bindez Insights collects and processes data that Users voluntarily provide in order to access or use its Services and data related to Users&rsquo; preferences and traffic (such as IP addresses).</p>
                    <br>
                    <p class="para">The purposes of this data processing are to enable Users to create an Account for accessing the Services, constantly improve the Services, and send commercial offers to Users.</p>
                    <br>
                    <p class="para">Users need to open an Account and provide Bindez Insights with at least the following personally identifiable data to use the Services:</p>
                    <ul class="fact-list--style">
                        <li style="font-weight: 400;">
                            Name;
                        </li>
                        <li style="font-weight: 400;">
                            Email address;
                        </li>
                        <li style="font-weight: 400;">
                            Company name.
                        </li>
                    </ul>
                    <br>
                    <p class="para">
                        Users may complete their profile with other Personal Data (their address, etc.).
                    </p>
                    <p class="para">
                        Bindez Insights will never collect or process Personal Data pertaining to, directly or
                        indirectly, race, health, sexual orientation, political, philosophical, or religious views.
                    </p>
                    <br>
                    <h2 class="privacy__title">II. Non-Personal Identifiable Data &amp; Cookies</h2>
                    <br>
                    <h2 class="privacy__sub-title">
                        2.1. Traffic
                    </h2>
                    <br>
                    <p class="para">
                        Traffic data are generated when Users&rsquo; terminals connect to the internet and are not
                        sufficient alone to identify them. Bindez Insights may collect the following data: origin of the
                        connection, type and version of Users internet browser, length of connection, etc.
                    </p>
                    <br>
                    <p class="para">
                        Such data may be used for statistical purposes to analyze the traffic and to improve the
                        Services. Traffic data are always processed anonymously.
                    </p>
                    <br>
                    <h2 class="privacy__sub-title">2.2. Cookies</h2>
                    <br>
                    <p class="para">
                        Bindez Insights places cookies on Users&rsquo; terminals. These small files may contain, for
                        instance, traffic data and Users&rsquo; preferences.
                    </p>
                    <br>
                    <p class="para">
                        The purposes of the cookies placed by Bindez Insights are to:
                    </p>
                    <ul class="fact-list--style">
                        <li>
                            authenticate Users (session ID cookies);
                        </li>
                        <li>
                            record Users&rsquo; visits and activities and to restore their web pages in order to enable certain features of the Services or Bindez Insights&rsquo; websites;
                        </li>
                        <li>
                            send targeted commercial offers to Users.
                        </li>
                    </ul>
                    <br>
                    <p class="para">
                        Bindez Insights authorizes its partners to place cookies on Users&rsquo; terminals to collect
                        Users&rsquo; data for marketing and statistical purposes.
                    </p>
                    <br>
                    <p class="para">Cookies are mandatory to use some of the Services and consequently must be accepted by Users. Users can configure their browser to disable cookies or to prompt them before accepting a cookie from the websites they visit. If Users disable cookies, however, they may not be able to use all portions of Bindez Insights&rsquo; websites or all functionalities of the Services.</p>
                    <h2 class="privacy__title">
                        III. Processing of Users&rsquo; Personal Data
                    </h2>
                    <br>
                    <p class="para">Bindez Insights collects and processes Users&rsquo; Personal Data in a fair and lawful manner and in respect of their rights. Bindez Insights is the entity responsible for processing Users&rsquo; Personal Data.</p>
                    <br>
                    <h2 class="privacy__title">
                        IV. Retention of Users&rsquo; Personal Data
                    </h2><br>
                    <h2 class="privacy__sub-title">
                        4.1. Security
                    </h2><br>
                    <p class="para">
                        The Personal Data that Users communicate to Bindez Insights are hosted by Bindez
                        Insights. Bindez Insights uses its best efforts to prevent any interference with Users&rsquo;
                        Personal Data such as loss, diversion, intrusion, unauthorized divulgation, alteration, or
                        destruction.
                    </p><br>
                    <h2 class="privacy__sub-title">4.2. Term</h2><br>
                    <p class="para">
                       Without prejudice to the following paragraph, the Personal Data processed by Bindez Insights will be deleted three (3) months after the cancellation of Users Account.</p>
                    <p class="para"><span style="font-weight: 400;">Bindez Insights is under duty keeping during one (1) year the following Personal Data as from the creation, change or deletion of Users Content:</span></p>
                    <ul class="fact-list--style">
                        <li style="font-weight: 400;">
                            Identifier of connection;
                        </li>
                        <li style="font-weight: 400;">
                            Identifier given by the computer;&nbsp;
                        </li>
                        <li style="font-weight: 400;">
                            Types of protocols;
                        </li>
                        <li style="font-weight: 400;">
                            Nature of the operation;&nbsp;
                        </li>
                        <li style="font-weight: 400;">
                            Date and hour of the operation;&nbsp;
                        </li>
                        <li style="font-weight: 400;">
                            Identifier used by the author of the operation.
                        </li>
                    </ul><br>
                    <h2 class="privacy__sub-title">
                        4.3. Cancellation of Account
                    </h2>
                    <br>
                    <p class="para">
                        Users may request that their Account be cancelled pursuant to the ToS. Their data will be
                        deleted by Bindez Insights without prejudice to section 4.2 above.
                    </p>
                    <br>
                    <h2 class="privacy__title">
                        V. Communication from Bindez Insights
                    </h2>
                    <br>
                    <p class="para">Bindez Insights may send emails to Users to the address associated with their account for technical or administrative purposes or to inform Users of the evolution of its Service. Bindez Insights will not send Users commercial offers unrelated to Bindez Insights.</p>
                    <br>
                    <p class="para">Bindez Insights will never send to Users commercial email if they refused to receive it.</p>
                    <br>
                    <h2 class="privacy__title">
                        VI. Transfer of Users&rsquo; Personal Data
                    </h2>
                    <br>
                    <p class="para">Bindez Insights undertakes not to share Users&rsquo; Personal Data with or transfer them to third parties unless they have consented prior to said sharing or transfer. Bindez Insights will never share Users personal data with third parties that do not guarantee an adequate protection.</p>

                    <p class="para">Bindez Insights may provide Users&rsquo; Personal Data if required by law or compelled by a court of law.</p><br>

                    <h2 class="privacy__title"><strong>VII. Right to Access, Modify and Delete Users&rsquo; Personal Data</strong></h2><br>
                    <p class="para">
                        Users have the right to access, modify, object to the processing of, and request the
                        deletion of the Personal Data collected or processed by Bindez Insights (excepted as provided in
                        section IV.).
                    </p>
                    <p class="para">Users may exercise this right by writing to Bindez Insights.</p>
                    <p class="para">Users may also modify their Personal Data directly from their Account.</p><br>
                    <p class="para">Still need help? Contact Us (<a style="text-decoration: none;" target="_blank" href="mailto:customersuccess@bindez.com"><span style="font-weight: 400;">customersuccess@bindez.com</span></a>)</p>
                </v-flex>
            </v-layout>
        </v-container>
<!--    </v-app>-->
</template>

<script>
	export default {
		name: "PrivacyPolicy",
	}
</script>

<style scoped>
    @import '../../public/css/bootstrap.min.css';
    @import '../../public/css/bindez.css';
    @import '../../public/css/bindez-custom2.css';

    @font-face {
        font-family: 'icomoon';
        src: url('../../public/fonts/icomoon.eot');
        src: url('../../public/fonts/icomoon.eot') format('embedded-opentype'),
        url('../../public/fonts/icomoon.ttf') format('truetype'),
        url('../../public/fonts/icomoon.woff') format('woff'),
        url('../../public/fonts/icomoon.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    [class^="icon-"],
    [class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'icomoon' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .icon-burmese:before {
        content: "\e900";
    }

    .icon-data:before {
        content: "\e901";
    }

    .icon-real-time:before {
        content: "\e902";
    }

    #lp-container {
        background-color: #fff;
    }

    .custom-modal-size > * > * {
        height: 500px;
    }

    /*.para{*/
    /*    font-weight: 400;*/
    /*    font-size: 20px;*/
    /*}*/

    p.para {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.8;
        margin-bottom: 16px;
    }

    h1{
        margin-top: 80px;
        font-size: 28px;
        color: #000;
        /*line-height: 1.5;*/
    }

    h2.privacy__title {
        font-weight: 900;
        font-size: 25px;
        margin-bottom: 0;
        color: #000;
    }

    h2.privacy__sub-title {
        font-weight: 900;
        font-size: 23px;
        margin-bottom: 0;
        color: #000;
    }

    /*.privacy__title{*/
    /*    font-weight: 900;*/
    /*    font-size: 25px;*/
    /*}*/

    /*.privacy__sub-title{*/
    /*    font-weight: 900;*/
    /*    font-size: 23px;*/
    /*}*/

    /*.privacy-list--style{*/
    /*    font-weight: 400;*/
    /*    font-size: 20px;*/
    /*}*/

    .fact-list--style {
        font-weight: 400;
        font-size: 20px;
    }

    .fact-list--style > li {
        line-height: 1.8;
    }
</style>
